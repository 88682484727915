@import "./fonts";

html, body {
  margin: 0;
  font-family: TochkaSans, "Times New Roman", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 400;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  touch-action: manipulation; /* Отключает жесты зума */
  overscroll-behavior: none; /* Блокирует случайную прокрутку */
}

a {
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

html, body, * {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
address {
  font-style: normal;
  font-weight: normal;
  margin: 0;
  padding: 0;
}

.app {
  position: relative;
  width: 100%;
  min-height: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'TochkaSans', sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 20px;
  color: #333;
  text-rendering: optimizeLegibility;
  -webkit-font-feature-settings: 'kern', 'liga' 0, 'calt' 0;
  font-feature-settings: 'kern', 'liga' 0, 'calt' 0;
  -moz-font-feature-settings: 'kern', 'liga' 0, 'calt' 0;
  -ms-font-feature-settings: 'kern', 'liga' 0, 'calt' 0;
  -o-font-feature-settings: 'kern', 'liga' 0, 'calt' 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -o-font-smoothing: antialiased;
  touch-action: pan-x pan-y;
  -webkit-text-size-adjust: none;
  overscroll-behavior: none;
}

.app * {
  outline: none;
}
.app img {
  border: 0;
}
.app strong {
  font-weight: 500;
}
.app ::-ms-clear,
.app ::-ms-reveal {
  display: none;
}
.app .root {
  height: 100%;
}
