$uiKitUrl: "/proxy/ui-kit";

@font-face {
  font-family: 'AngleciaProTitle-Bold';
  src: url($uiKitUrl + "/static/fonts/Anglecia/WOFF2/AngleciaProTitle-Bold.woff2") format('woff2'),
  url($uiKitUrl + "/static/fonts/Anglecia/WOFF/AngleciaProTitle-Bold.woff") format('woff');
}

$bodyTextFontsMap: (
  "300": (
    "embedded-opentype": "/static/fonts/TTNormsProTochka/EOT/TT_Norms_Pro_Tochka_Light.eot",
    "woff2": "/static/fonts/TTNormsProTochka/WOFF2/TT_Norms_Pro_Tochka_Light.woff2",
    "woff": "/static/fonts/TTNormsProTochka/WOFF/TT_Norms_Pro_Tochka_Light.woff",
    "truetype": "/static/fonts/TTNormsProTochka/TTF/TT_Norms_Pro_Tochka_Light.ttf",
    "opentype": "/static/fonts/TTNormsProTochka/OTF/TT_Norms_Pro_Tochka_Light.otf"
  ),
  "normal": (
    "embedded-opentype": "/static/fonts/TTNormsProTochka/EOT/TT_Norms_Pro_Tochka_Regular.eot",
    "woff2": "/static/fonts/TTNormsProTochka/WOFF2/TT_Norms_Pro_Tochka_Regular.woff2",
    "woff": "/static/fonts/TTNormsProTochka/WOFF/TT_Norms_Pro_Tochka_Regular.woff",
    "truetype": "/static/fonts/TTNormsProTochka/TTF/TT_Norms_Pro_Tochka_Regular.ttf",
    "opentype": "/static/fonts/TTNormsProTochka/OTF/TT_Norms_Pro_Tochka_Regular.otf"
  ),
  "400": (
    "embedded-opentype": "/static/fonts/TTNormsProTochka/EOT/TT_Norms_Pro_Tochka_Regular.eot",
    "woff2": "/static/fonts/TTNormsProTochka/WOFF2/TT_Norms_Pro_Tochka_Regular.woff2",
    "woff": "/static/fonts/TTNormsProTochka/WOFF/TT_Norms_Pro_Tochka_Regular.woff",
    "truetype": "/static/fonts/TTNormsProTochka/TTF/TT_Norms_Pro_Tochka_Regular.ttf",
    "opentype": "/static/fonts/TTNormsProTochka/OTF/TT_Norms_Pro_Tochka_Regular.otf"
  ),
  "500": (
    "embedded-opentype": "/static/fonts/TTNormsProTochka/EOT/TT_Norms_Pro_Tochka_Medium.eot",
    "woff2": "/static/fonts/TTNormsProTochka/WOFF2/TT_Norms_Pro_Tochka_Medium.woff2",
    "woff": "/static/fonts/TTNormsProTochka/WOFF/TT_Norms_Pro_Tochka_Medium.woff",
    "truetype": "/static/fonts/TTNormsProTochka/TTF/TT_Norms_Pro_Tochka_Medium.ttf",
    "opentype": "/static/fonts/TTNormsProTochka/OTF/TT_Norms_Pro_Tochka_Medium.otf"
  ),
  "700": (
    "embedded-opentype": "/static/fonts/TTNormsProTochka/EOT/TT_Norms_Pro_Tochka_DemiBold.eot",
    "woff2": "/static/fonts/TTNormsProTochka/WOFF2/TT_Norms_Pro_Tochka_DemiBold.woff2",
    "woff": "/static/fonts/TTNormsProTochka/WOFF/TT_Norms_Pro_Tochka_DemiBold.woff",
    "truetype": "/static/fonts/TTNormsProTochka/TTF/TT_Norms_Pro_Tochka_DemiBold.ttf",
    "opentype": "/static/fonts/TTNormsProTochka/OTF/TT_Norms_Pro_Tochka_DemiBold.otf"
  ),
  "800": (
    "embedded-opentype": "/static/fonts/TTNormsProTochka/EOT/TT_Norms_Pro_Tochka_Bold.eot",
    "woff2": "/static/fonts/TTNormsProTochka/WOFF2/TT_Norms_Pro_Tochka_Bold.woff2",
    "woff": "/static/fonts/TTNormsProTochka/WOFF/TT_Norms_Pro_Tochka_Bold.woff",
    "truetype": "/static/fonts/TTNormsProTochka/TTF/TT_Norms_Pro_Tochka_Bold.ttf",
    "opentype": "/static/fonts/TTNormsProTochka/OTF/TT_Norms_Pro_Tochka_Bold.otf"
  ),
  "900": (
    "embedded-opentype": "/static/fonts/TTNormsProTochka/EOT/TT_Norms_Pro_Tochka_ExtraBold.eot",
    "woff2": "/static/fonts/TTNormsProTochka/WOFF2/TT_Norms_Pro_Tochka_ExtraBold.woff2",
    "woff": "/static/fonts/TTNormsProTochka/WOFF/TT_Norms_Pro_Tochka_ExtraBold.woff",
    "truetype": "/static/fonts/TTNormsProTochka/TTF/TT_Norms_Pro_Tochka_ExtraBold.ttf",
    "opentype": "/static/fonts/TTNormsProTochka/OTF/TT_Norms_Pro_Tochka_ExtraBold.otf"
  )
);

@each $fontWeight, $_map in $bodyTextFontsMap {
  @font-face {
    font-family: "TochkaSans";
    font-weight: unquote($fontWeight);
    font-style: normal;

    $url: ();
    @each $fontExtension, $fontRelativeUrl in $_map {
      $fontUrl: $uiKitUrl + $fontRelativeUrl;

      @if $fontExtension == 'embedded-opentype' {
        src: url($fontUrl);
        $url: append($url, url($fontUrl + "?#iefix") format($fontExtension), $separator: comma);
      } @else {
        $url: append($url, url($fontUrl) format($fontExtension), $separator: comma);
      }
    }
    src: $url;
  }
}
